import type { DeepPartial } from "@apollo/client/utilities";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import {
  type OrderItem,
  type ProductModifierOption,
  ProductModifierSpecialSubtype,
  type SourceProduct,
  type SourceType,
} from "../../../../generated/requests/pos";
import { ImagesV2 } from "../../../../public/images/all";
import { formatMoney } from "../../../../static/lib/util";
import Text from "../../atoms/Text/Text";
import type { ClientOrderItem } from "../../contexts/OrderContext/helpers";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import { getUniqueItemId } from "../../contexts/OrderContextNew/helpers";
import Button from "../../molecules/Button/Button";
import Modal from "../../molecules/Modal/Modal";
import { useGetSource, useGetStoreBySlug } from "../../operations/queries";

interface AddGiftWrapModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedProduct: DeepPartial<SourceProduct>;
  onAddToBag?: (selectedGiftWrapModifier: ProductModifierOption) => void;
  setIsThisAGift?: (isThisAGift: boolean) => void;
}

export function AddGiftWrapModal({
  isOpen,
  onClose,
  selectedProduct,
  onAddToBag,
  setIsThisAGift,
}: AddGiftWrapModalProps) {
  return (
    <GiftWrapModal
      isOpen={isOpen}
      onClose={onClose}
      selectedProduct={selectedProduct}
      onAddToBag={onAddToBag}
      setIsThisAGift={setIsThisAGift}
    />
  );
}

interface UpdateGiftWrapModalProps {
  isOpen: boolean;
  onClose?: () => void;
  itemToUpdate?: OrderItem;
  isUpdate?: boolean;
}

export function UpdateGiftWrapModal({ itemToUpdate, isOpen, onClose, isUpdate }: UpdateGiftWrapModalProps) {
  return <GiftWrapModal itemToUpdate={itemToUpdate} isOpen={isOpen} onClose={onClose} isUpdate={isUpdate} />;
}

interface GiftWrapModalProps {
  index?: number;
  itemToUpdate?: OrderItem;
  selectedProduct?: DeepPartial<SourceProduct>;
  isOpen: boolean;
  onAddGiftWrap?: (giftWrapModifier: ProductModifierOption) => void;
  onRemoveGiftWrap?: (orderItemIndex: number, itemToUpdate: ClientOrderItem) => void;
  onUpdateSelection?: (
    orderItemIndex: number,
    itemToUpdate: ClientOrderItem,
    giftWrapModifier: ProductModifierOption,
  ) => void;
  giftWrapOptions?: ProductModifierOption[];
  hideCloseButton?: boolean;
  onClose?: () => void;
  initialGiftWrapOption?: ProductModifierOption;
  isUpdate?: boolean;
  backClickHandler?: () => void;
  onAddToBag?: (selectedGiftWrapModifier: ProductModifierOption) => void;
  setIsThisAGift?: (isThisAGift: boolean) => void;
}

const GiftWrapModal = ({
  itemToUpdate,
  selectedProduct,
  isOpen,
  hideCloseButton = false,
  onClose,
  isUpdate,
  onAddToBag,
  setIsThisAGift,
}: GiftWrapModalProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { order, orderTimeSlot, upsertOrderItems } = useOrderContextNew();
  const [selectedGiftWrap, setSelectedGiftWrap] = useState<ProductModifierOption | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const type = router.query.type as string;
  const orderType = type?.toUpperCase() as SourceType;
  const storeSlug = router.query.slug as string;

  const { data: storeData } = useGetStoreBySlug({ storeSlug: storeSlug });
  const { data: sourceProductsData } = useGetSource({
    storeId: storeData?.storeId,
    type: orderType,
    timeSlot: orderTimeSlot,
  });

  let product: DeepPartial<SourceProduct> = selectedProduct;
  if (!product) {
    product = sourceProductsData?.public?.sourceForStore?.products?.find(
      (p) => p?.product?.productId === itemToUpdate?.product?.productId,
    );
  }

  const giftWrapModifier = product?.product?.modifiers?.find((m) =>
    m?.specialSubtypes?.includes(ProductModifierSpecialSubtype.Giftwrapping),
  );

  const handleUpsertGiftWrap = async (giftWrapOption: ProductModifierOption) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      if (isUpdate) {
        const updatedModifiers = itemToUpdate.modifiers
          .map((modifier) => {
            if (modifier.modifierId === giftWrapModifier?.modifierId) {
              return {
                ...modifier,
                options: giftWrapOption ? [giftWrapOption] : [],
              };
            }
            return modifier;
          })
          .filter((m) => m.options.length > 0);
        await upsertOrderItems({
          order: order,
          items: [
            ...(order?.items?.map((item) => {
              if (getUniqueItemId(item) === getUniqueItemId(itemToUpdate)) {
                return {
                  ...item,
                  modifiers: updatedModifiers,
                };
              }
              return item;
            }) || []),
          ],
        });
      } else {
        // add gift wrap and product to order
        await onAddToBag(giftWrapOption);
      }
      onClose?.();
    } catch (error) {
      console.error("Error updating gift wrap: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNoGiftWrapClick = () => {
    if (isUpdate) {
      handleUpsertGiftWrap(null);
    } else {
      setIsThisAGift(false);
      onClose();
    }
  };
  return (
    <Modal
      variant="secondaryNoDivider"
      isOpen={isOpen}
      onClose={onClose}
      dontCloseOnOutsideClick
      hideCloseButton={hideCloseButton}
      className="!bg-white"
    >
      <div className="flex flex-col gap-y-8 my-auto sm:max-w-[466px] sm:min-h-[644px] mx-auto">
        <div className="flex flex-col items-center relative">
          <Text className="hidden sm:inline-flex mt-1" variant="title1">
            {t("order:gift_wrap_options")}
          </Text>
          <Text className="sm:hidden mt-5" variant="title2">
            {t("order:select_your_gift_wrap")}
          </Text>
          <Image
            className="pb-5 w-3/4 relative right-[20px]"
            src={ImagesV2.giftwrapWithBow}
            alt={ImagesV2.illustrationGiftingBow}
          />
          <div className="px-[15px] pt-5 items-center">
            <div className="flex flex-col pb-[30px] items-center gap-y-1 text-center">
              <Text className="hidden sm:inline-flex" variant="title1">
                {t("order:select_your_gift_wrap")}
              </Text>
              <Text className="sm:text-lg">{t("order:gift_wrap_comes_with_bow")}</Text>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-3">
            {giftWrapModifier?.options?.map((giftWrapOption) => (
              <button
                key={giftWrapOption?.optionId}
                id={giftWrapOption?.optionId}
                disabled={giftWrapOption.metadata?.soldOut}
                onClick={isLoading ? undefined : () => setSelectedGiftWrap(giftWrapOption as ProductModifierOption)}
                className={classNames(
                  "relative flex items-center justify-center hover:cursor-pointer border border-gray-300 rounded-lg",
                  {
                    "opacity-50": isLoading,
                    "bg-primary-light": selectedGiftWrap?.optionId === giftWrapOption.optionId && giftWrapOption?.image,
                    "border-none": !giftWrapOption?.image,
                  },
                )}
              >
                <Image
                  src={giftWrapOption?.image || ImagesV2.blackCircle}
                  alt={giftWrapOption?.name}
                  width={110}
                  height={110}
                />
                {!giftWrapOption?.image && (
                  <Text className="absolute text-center text-white text-wrap px-2 py-[12px]">
                    {giftWrapOption.name}
                  </Text>
                )}
                {giftWrapOption.optionId === selectedGiftWrap?.optionId && (
                  <Image
                    src={ImagesV2.checkCircleWhite}
                    alt={ImagesV2.checkCircleWhite}
                    className="absolute top-0 right-0"
                  />
                )}
              </button>
            ))}
          </div>
        </div>

        <div
          className={classNames("flex flex-col w-full gap-3 items-center mt-12 sm:mt-auto", {
            "opacity-50": isLoading,
          })}
        >
          <Button
            id="addGiftWrap"
            block
            onClick={() => handleUpsertGiftWrap(selectedGiftWrap)}
            disabled={!selectedGiftWrap}
          >
            {!isUpdate
              ? t("order:add_gift_wrap", {
                  x: selectedGiftWrap?.price ? `(+ ${formatMoney(selectedGiftWrap?.price)})` : "",
                })
              : t("order:update_selection")}
          </Button>

          {onClose && (
            <Button
              id="no_gift_wrap"
              className="inline-flex"
              variant="secondary"
              block
              onClick={() => handleNoGiftWrapClick()}
            >
              {isUpdate ? t("order:remove_gift_wrap") : t("order:no_gift_wrap")}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
