import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import classNames from "classnames";
import dayjs from "dayjs";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { Fragment, HTMLProps } from "react";
import IconBrandFacebookFilled from "../../atoms/Icons/BrandFacebookFilled";
import IconBrandInstagramFilled from "../../atoms/Icons/BrandInstagramFilled";
import IconBrandLinkedinFilled from "../../atoms/Icons/BrandLinkedinFilled";
import IconBrandPinterestFilled from "../../atoms/Icons/BrandPinterestFilled";
import IconBrandTiktokFilled from "../../atoms/Icons/BrandTiktokFilled";
import IconBrandXFilled from "../../atoms/Icons/BrandXFilled";
import IconBrandYoutubeFilled from "../../atoms/Icons/BrandYoutubeFilled";
import IconMinusFilled from "../../atoms/Icons/MinusFilled";
import IconPlusFilled from "../../atoms/Icons/PlusFilled";
import IconWordmark from "../../atoms/Icons/Wordmark";
import Text from "../../atoms/Text/Text";
import { useUserTrackingConsentContext } from "../../contexts/UserTrackingConsentContext/UserTrackingConsentContext";

const footerLinkGroups = [
  {
    title: "company",
    links: [
      {
        title: "order",
        href: "/order"
      },
      {
        title: "our_story",
        href: "/our-story"
      },
      {
        title: "rewards",
        href: "/rewards"
      },
      {
        title: "nutrition_and_allergy",
        href: "/nutrition"
      },
      {
        title: "support",
        href: "/contact"
      }
    ]
  },
  {
    title: "get_involved",
    links: [
      {
        title: "media_kit",
        href: "/press"
      },
      {
        title: "collaborate",
        href: "/collaborate"
      },
      {
        title: "franchising",
        href: "/franchising"
      },
      {
        title: "franchise_store_jobs",
        href: "/jobs"
      },
      {
        title: "hq_careers",
        href: "/careers"
      },
      {
        title: "crumbl_cares",
        href: "/crumbl-cares"
      }
    ]
  }
];

const socialLinks = [
  {
    Icon: IconBrandFacebookFilled,
    href: "https://www.facebook.com/crumblcookies/"
  },
  {
    Icon: IconBrandInstagramFilled,
    href: "https://www.instagram.com/crumblcookies/"
  },
  {
    Icon: IconBrandTiktokFilled,
    href: "https://www.tiktok.com/@crumblcookies"
  },
  {
    Icon: IconBrandXFilled,
    href: "https://x.com/crumblcookies"
  },
  {
    Icon: IconBrandYoutubeFilled,
    href: "https://www.youtube.com/c/CrumblCookies"
  },
  {
    Icon: IconBrandLinkedinFilled,
    href: "https://www.linkedin.com/company/crumblcookies/"
  },
  {
    Icon: IconBrandPinterestFilled,
    href: "https://www.pinterest.com/crumblcookies/"
  }
];

export default function Footer({ className }: HTMLProps<HTMLDivElement>) {
  const { t } = useTranslation();
  const { setShowPreferences } = useUserTrackingConsentContext();
  return (
    <footer className={classNames("border-t border-primary", className)}>
      <nav className="flex flex-col px-7 lg:px-[100px] py-[50px] gap-y-[30px] max-w-9xl mx-auto">
        {footerLinkGroups.map((group) => (
          <Fragment key={group.title}>
            <div
              key={group.title}
              className="hidden md:flex flex-col lg:flex-row w-full items-center justify-center lg:gap-x-[10px] xl:gap-x-[30px]"
            >
              <Text variant="title3" className="mb-2 lg:mb-0">
                {t(group.title)}
              </Text>
              {group.links.map(({ title, href }) => (
                <Link
                  key={href}
                  href={href || ""}
                  className="py-1.5 px-[15px] rounded-[4px] hover:bg-primary focus:bg-primary transition-colors"
                >
                  <Text variant="body1">{t(title)}</Text>
                </Link>
              ))}
            </div>
            <Disclosure>
              {({ open }) => (
                <div className="flex md:hidden flex-col lg:flex-row w-full items-center justify-start lg:gap-x-[10px] xl:gap-x-[30px]">
                  <DisclosureButton className="flex items-center justify-between w-full mb-4">
                    <Text variant="title3">{t(group.title)}</Text>
                    {open ? <IconMinusFilled className="size-5" /> : <IconPlusFilled className="size-5" />}
                  </DisclosureButton>
                  <DisclosurePanel className="justify-start w-full">
                    {group.links.map(({ title, href }) => (
                      <div className="w-full" key={href}>
                        <Link
                          href={href || ""}
                          className="py-1.5 px-[15px] rounded-[4px] hover:bg-primary focus:bg-primary transition-colors inline-block w-auto"
                        >
                          <Text variant="body1">{t(title)}</Text>
                        </Link>
                      </div>
                    ))}
                  </DisclosurePanel>
                </div>
              )}
            </Disclosure>
          </Fragment>
        ))}
        <div className="flex flex-row flex-wrap w-full items-center justify-center gap-x-4 sm:gap-x-[30px]">
          {socialLinks.map(({ Icon, href }) => (
            <Link key={href} href={href}>
              <Icon width={30} height={30} />
            </Link>
          ))}
        </div>
      </nav>
      <div className="px-7 py-[50px] bg-primary">
        <div className="flex flex-col items-center justify-center max-w-9xl mx-auto">
          <div className="w-1/3 max-w-[500px] min-w-[300px]">
            <IconWordmark className="w-full h-[154px] max-w-full" />
          </div>
          <Text variant="finePrint" className="!text-black">
            &copy;&nbsp;{dayjs().year()}&nbsp;{t("all_rights_reserved")}&nbsp;
          </Text>
          <div className="flex flex-col items-center gap-y-4 md:flex-row">
            <Text variant="finePrint" className="!text-black md:ml-1 flex flex-col items-center gap-y-4 sm:flex-row">
              <Link href="/privacy">{t("privacy_policy")}</Link>
              <span className="hidden sm:inline mx-1 !text-grey-20">|</span>
              <Link href="/termsandconditions">{t("terms_and_conditions")}</Link>
              <span className="hidden sm:inline mx-1 !text-grey-20">|</span>
              <button onClick={() => setShowPreferences(true)}>{t("non_edible_cookie_preferences")}</button>
            </Text>
          </div>
        </div>
      </div>
    </footer>
  );
}
