import Text from "@/components/v2/atoms/Text/Text";
import classNames from "classnames";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import { useTranslation } from "react-i18next";
import IconCalendarClock from "../Icons/CalendarClock";

dayjs.extend(timezone);

type PreOrderTagProps = {
  isPreorder?: boolean;
  isDesktopVersion?: boolean;
};

export default function PreOrderTag({ isPreorder = undefined, isDesktopVersion = false }: PreOrderTagProps) {
  const { orderTimeSlot } = useOrderContextNew();
  const today = dayjs();
  const isSameDayOrder = today.isSame(orderTimeSlot, "day");
  const { t } = useTranslation("common");

  return isDesktopVersion ? (
    <div
      className={classNames(
        "bg-primary-light py-[4px] px-[10px] rounded-[5px] flex items-center justify-center h-min w-fit min-w-max gap-1",
        {
          hidden: isPreorder === undefined ? isSameDayOrder : !isPreorder,
        },
      )}
    >
      <IconCalendarClock width={18} height={18} />
      <Text variant="caption3">{t("pre_order_in_progress")}</Text>-
      <Text variant="body2">{t("make_sure_date_is_correct")}</Text>
    </div>
  ) : (
    <div
      className={classNames(
        "bg-primary-light py-[4px] px-[10px] rounded-[5px] flex items-center justify-center h-min w-fit min-w-max",
        {
          hidden: isPreorder === undefined ? isSameDayOrder : !isPreorder,
        },
      )}
    >
      <Text variant="caption3">Preorder</Text>
    </div>
  );
}
